<template>
  <div class="app-select">
    <label v-if="label" :for="id" class="app-select__label">{{
      $t(label)
    }}</label>
    <select
      :disabled="disabled"
      :id="id"
      :name="name"
      :value="value"
      @input="onInput"
      class="app-select__select"
    >
      <template v-if="placeholder">
        <option disabled value="">{{ placeholder }}</option>
      </template>

      <slot name="default">
        <option
          v-for="(option, index) in options"
          :key="index"
          :value="isObject ? JSON.stringify(option.value) : option.value"
        >
          {{ $t(option.name) }}
        </option>
      </slot>
    </select>
    <!-- <i class="mdi mdi-chevron-down app-select__icon"></i> -->
    <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />

  </div>
</template>

<script>
import { ChevronDownIcon } from '@heroicons/vue/20/solid'


export default {
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
    name: {
      type: String,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      required: false,
    },
    isObject: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    onInput(event) {
      this.$emit('selectionChange', event.target.value)
    },
  },
}
</script>
